import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import { graphql } from "gatsby"

import HeroContainer from "../components/heroContainer"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import CardTitle from "../components/CardTitle"
import Album from "../components/albums/Album"
import RenderEventCards from "../components/events/RenderEventCards"
import { addExtraDateInfo } from "../helperFunctions"
import { AiOutlineArrowDown } from "react-icons/ai"
import ButtonLink from "../components/ButtonLink"
import PageTitle from "../components/PageTitle"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import BackgroundContainer from "../components/containers/BackgroundContainer"
import StandardContainer from "../components/containers/StandardContainer"

const Education = ({ data }) => {
  // get the information with out edges.node
  const { sanityEducationPage, events } = data

  const eventsWithDateInfo = addExtraDateInfo(events.edges)

  const upcomingEvents = eventsWithDateInfo.filter(
    (event) => !event.isPastEvent
  )
  const pastEvents = eventsWithDateInfo.filter((event) => event.isPastEvent)

  const defaultHeroFluid = data.heroImage.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      sanityEducationPage?.rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  const heroProps = {
    fluid: fluidBanner,
    gradient: true,
  }

  return (
    <Layout>
      <SEO title={sanityEducationPage.title} />
      <HeroContainer props={heroProps} />
      <BackgroundContainer>
        <StandardContainer
          props={{
            fluid: null,
            className: "pt-20 px-5 md:px-10 relative text-primary",
          }}
        >
          <div className="grid justify-items-end">
            <ButtonLink
              to="/education/#events"
              className="flex items-center gap-x-2 uppercase tracking-wide"
            >
              Events
              <AiOutlineArrowDown />
            </ButtonLink>
          </div>
          <PageTitle text="Education" />
          <div className="grid gap-y-16 my-10 ">
            {sanityEducationPage.sections.map((section) => {
              const images = section._rawMedia
                ? section._rawMedia.filter((item) => item._type === "miscImage")
                : null

              const videos = section._rawMedia
                ? section._rawMedia.filter(
                    (item) => item._type === "singleFile"
                  )
                : null

              return (
                <div className="">
                  {section?.title ? (
                    <CardTitle title={{ text: section.title }} />
                  ) : null}
                  <BlockContentPrimary
                    blockData={section._rawRichTextContent}
                  />
                  {images && images.length >= 1 && (
                    <div className="grid justify-items-center">
                      <div className=" w-72 md:w-96">
                        <Album rawImageData={images} />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </StandardContainer>
        <StandardContainer className="px-5 md:px-10 pb-20">
          {/* Events */}
          <div id="events" className="pt-10">
            <CardTitle title={{ text: "Education Events" }} />
            <div className="grid gap-y-10 justify-items-center">
              {/* Upcoming Events */}
              <RenderEventCards
                sectionTitle="Upcoming Events"
                eventsData={upcomingEvents}
              />
              {/* Past Events */}
              <RenderEventCards
                sectionTitle="Past Events"
                eventsData={pastEvents}
              />
            </div>
          </div>
        </StandardContainer>
      </BackgroundContainer>
    </Layout>
  )
}
export default Education

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "home_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sanityEducationPage {
      title
      rawHeaderImage: _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      sections {
        title
        _rawRichTextContent(resolveReferences: { maxDepth: 10 })
        _rawMedia(resolveReferences: { maxDepth: 10 })
      }
    }
    events: allSanityEducationEvent(
      sort: { fields: eventDateTime, order: DESC }
    ) {
      edges {
        node {
          ...EducationEventCard
        }
      }
    }
  }
`
