// Render an album from raw sanity image data

import React from "react"
import urlBuilder from "@sanity/image-url"
import ImageGallery from "react-image-gallery"

import { mySanityClient } from "../../sanityClient"

const Album = ({ rawImageData, thumbnailPosition = "top" }) => {
  const builder = urlBuilder(mySanityClient)

  // Get the url for a given source
  const urlFor = (source) => builder.image(source)

  // create an array of urls for the album
  const imageUrlArray = rawImageData.map((imageData) => {
    const url = urlFor(imageData.asset.id)
      .width(1000)
      .format("jpg")
      .fit("max")
      .url()

    const previewUrl = urlFor(imageData.asset.id)
      .width(200)
      .height(200)
      .format("jpg")
      .url()
    // const previewUrl = url + "?h=200" + "&w=200" + "&fm=jpg"

    const altText = imageData.altText || "Alt Text Not Provided"

    return {
      original: url,
      originalAlt: altText,
      description: imageData.title || null,
      thumbnail: previewUrl,
      thumbnailAlt: altText,
    }
  })

  const isMultipleImages = () => (imageUrlArray.length > 1 ? true : false)

  return (
    <ImageGallery
      items={imageUrlArray}
      thumbnailPosition={thumbnailPosition}
      lazyLoad
      translate3d
      showPlayButton={false}
      showBullets={isMultipleImages()}
      showThumbnails={false}
    />
  )
}

export default Album
