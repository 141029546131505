import React from "react"

const CardTitle = ({
  title = { text: "Card Title", color: null, className: "" },
  lineClassName = "",
  className = "",
}) => {
  return (
    <div className={`grid ${className}`}>
      <h3
        className={` text-center md:text-left text-4xl uppercase tracking-wider ${
          title.color || "text-secondary "
        } ${title.className}`}
      >
        {title.text}
      </h3>
      <div
        className={`bg-fifth w-3/4 md:w-1/3 justify-self-center md:justify-self-start my-3 ${lineClassName}`}
        style={{ height: "2px" }}
      />
    </div>
  )
}

export default CardTitle
