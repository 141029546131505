import React, { useState } from "react"
import DisplayCard from "../DisplayCard"

const RenderEventCards = ({ eventsData, sectionTitle }) => {
  const [numCards, setNumCards] = useState(3)

  const slicedArray = eventsData.slice(0, numCards)

  const handleClick = () => setNumCards(numCards + 3)

  return (
    <>
      <div className="w-full grid justify-items-center mt-10">
        <h2 className=" leading-tight text-center text-quaternary mb-3 uppercase">
          {sectionTitle}
        </h2>
        <div className=" border-b-2 border-secondary w-1/4"></div>
      </div>
      <div className="grid gap-y-10">
        {slicedArray.length ? (
          slicedArray.map((event) => {
            const eventCardProps = {
              id: event.id,
              title: event.title,
              date: event.dateString,
              summary: event.summary,
              rawContent: event.rawContent,
              time: event.time,
              handle: `/education/${event.slug.current}`,
              buttonText: "More Info",
              fluid: event?.bannerHeroImage?.asset?.fluid || undefined,
              altText: event?.bannerHeroImage?.altText || undefined,
              location: event?.eventLocation || undefined,
            }

            return (
              <div className="bg-white shadow-md border p-5">
                <DisplayCard {...eventCardProps} />
              </div>
            )
          })
        ) : (
          <div className=" font-heading text-2xl md:text-3xl text-center">
            No events to show you. Check again soon!
          </div>
        )}
      </div>
      {numCards < eventsData.length && (
        <button
          className="text-xl px-3 py-1  bg-quaternary  text-white hover:bg-white hover:text-quaternary border-2 border-quaternary  font-semibold justify-self-center transition duration-100 ease-in-out transform hover:scale-105"
          onClick={handleClick}
        >
          Show More
        </button>
      )}
    </>
  )
}

export default RenderEventCards
